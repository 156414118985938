<template>
  <div class="py-6 px-12 list-mdt">
    <span
      class="flex text-blue-900 text-2xl font-semibold"
    >{{ $t("livestream.lbl_livestream") }}</span>

    <div class="row mt-2 body-list justify-center">
      <div v-if="!hasLivestreamRoom" class="text-center col-md-6">
        <img src="../../../public/assets/images/MDT/Frame.png" class="img-1 mb-3" alt />
        <p class="flex text-2xl font-semibold mb-6"
          >{{ $t("livestream.lbl_no_livestream_yet") }}</p>
        <div class="dropdown">
          <button class="btn bg-pri bd-pri text-white mt-3" @click="creatLivestreamRoom">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.25 8.29008C3.25 6.75108 3.51546 5.96528 3.95033 5.53041C4.3852 5.09553 5.171 4.83008 6.71 4.83008H13.03C14.569 4.83008 15.3548 5.09553 15.7897 5.53041C16.2245 5.96528 16.49 6.75108 16.49 8.29008V16.7101C16.49 18.2476 16.2226 19.0339 15.7864 19.4692C15.4522 19.8028 14.9128 20.0364 13.9902 20.1276C13.7662 20.6803 13.4737 21.1979 13.1231 21.6698C14.6974 21.6598 15.982 21.3933 16.8461 20.5309C17.7274 19.6512 17.99 18.3325 17.99 16.7101V8.29008C17.99 6.66908 17.7305 5.34987 16.8503 4.46975C15.9702 3.58962 14.651 3.33008 13.03 3.33008H6.71C5.089 3.33008 3.7698 3.58962 2.88967 4.46975C2.00954 5.34987 1.75 6.66908 1.75 8.29008V13.5068C2.16586 12.9091 2.67252 12.3794 3.25 11.9375V8.29008Z"
                fill="white"
              />
              <path
                d="M20.02 17.6001L17.24 15.6501V9.34013L20.02 7.39013C21.38 6.44013 22.5 7.02013 22.5 8.69013V16.3101C22.5 17.9801 21.38 18.5601 20.02 17.6001Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13 9.5C13.8284 9.5 14.5 8.82843 14.5 8C14.5 7.17157 13.8284 6.5 13 6.5C12.1716 6.5 11.5 7.17157 11.5 8C11.5 8.82843 12.1716 9.5 13 9.5Z"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <circle cx="7.5" cy="17.5" r="5.25" stroke="white" stroke-width="1.5" />
              <path d="M7.5 14.5V20.5" stroke="white" stroke-width="1.5" />
              <path d="M10.5 17.5L4.5 17.5" stroke="white" stroke-width="1.5" />
            </svg>
            {{ $t("livestream.lbl_create_livestream") }}
          </button>
        </div>
      </div>
      <div v-if="hasLivestreamRoom">
        <p class="flex text-2xl font-semibold">Phòng phát trực tiếp {{ $globalOrg.name }}</p>
        <LivestreamAssignRole v-if="checkRoleOwnerOrg($globalOrg)" :live_room_id="live_room_id" :roles="roles" :user_role="user_role"/>
        <div v-else :set="member = $user">
          <p class="text-xl font-semibold">Phân công vai trò nhân viên Livestream</p>
          <div class="d-flex align-items-center">
            <p class="d-flex align-items-center mb-0 w-30">
              <span
                v-if="member && member.avatar"
                class="img-avatar"
                :style="{
                'background-image':
                    'url(' + getUrlImage(member.avatar) + ')',
                }"
              ></span>
              <span v-if="member && !member.avatar" class="img-avatar">
                {{
                  member.name
                  ? member.name.charAt(0)
                  : member.email
                  ? member.email.charAt(0)
                  : ""
                }}
              </span>
              <span class="list-item-name">
                <span class="d-block mb-0 robo-14-400">
                  {{
                    member && member.name
                  }}
                </span>
                <span class="robo-12-400" v-if="member.id">
                  {{
                    member && member.chuc_vu
                  }}
                </span>
              </span>
            </p>
            <el-select
              :value="'viewer'"
              class="w-30"
              disabled
            >
              <el-option
                v-for="role in roles"
                :key="role.role_id"
                :value="role.name"
                :label="role.name_capitalized"
              ></el-option>
            </el-select>
          </div>
          <button
            class="btn bg-pri bd-pri text-white save-btn mt-3"
            :disabled="loading"
            type="button"
            @click="goToStream"
          >{{ $t("circle_feature.lbl_go_to_conference") }}</button>
        </div>
      </div>
    </div>

    <div class="loading" v-if="loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

  </div>
</template>

<script>
import LivestreamAssignRole from './LivestreamAssignRole'
import appUtils from '../../utils/appUtils'

export default {
  name: 'Livestream',
  components: {LivestreamAssignRole},
  data () {
    return {
      loading: true,
      hasLivestreamRoom: false,
      live_room_id: null,
      roles: [],
      appUtils,
      user_role: ''
    }
  },
  async created () {
    await this.getLivestreamRoom()
    await this.getListRolesInRoom()
  },
  methods: {
    async creatLivestreamRoom () {
      this.loading = true
      await this.$rf.getRequest('DoctorRequest')
        .createOrgLivestreamRoom(this.$globalOrg.id)
        .then((res) => {
          this.live_room_id = res.data.live_room_id
          this.hasLivestreamRoom = true
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getLivestreamRoom () {
      this.loading = true
      await this.$rf.getRequest('DoctorRequest')
        .getOrgLivestreamRoom(this.$globalOrg.id)
        .then((res) => {
          if (res.data.live_room_id) {
            this.live_room_id = res.data.live_room_id
            this.hasLivestreamRoom = true
          } else {
            this.hasLivestreamRoom = false
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getListRolesInRoom () {
      try {
        this.loading = true
        await this.$rf
          .getRequest('DoctorRequest')
          .getListRolesInRoom('livestream')
          .then(res => {
            const roleArr = Object.keys(res.data.data[0].roles)
            this.roles = roleArr
              .map(role => {
                const role_name = role.charAt(0).toUpperCase() + role.slice(1)
                return {
                  ...res.data.data[0].roles[role],
                  name_capitalized: role_name
                }
              })
              .filter(role => !['__internal_recorder', 'hls-viewer'].includes(role.name))
          })
          .finally(() => {
            this.loading = false
          })
      } catch (error) {
        console.log(error)
      }
    },
    async goToStream () {
      await this.getCurrentUserRole().then(() => {
        let token = appUtils.getLocalToken()
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.live.hodo.app'
        } else {
          dm = 'https://live.hodo.app'
        }
        // TODO
        const routeRaw = `${dm}/redirect.html?token=${token}&room_id=${this.live_room_id}&org_id=${this.$globalOrg.id}&type=streaming&role=${this
          .user_role || 'viewer'}`
        window.open(routeRaw, '_blank')
      })
    },
    async getCurrentUserRole () {
      await this.$rf
        .getRequest('DoctorRequest')
        // TODO
        .checkMyRoleLivestreamRoom({
          org_id: this.$globalOrg.id,
          user_id: this.$user.id
        })
        .then(res => {
          this.user_role = res.data?.user_role || 'viewer'
        })
        .catch(e => {
          console.log(e)
          this.$toast.open({
            message: e,
            type: 'error'
          })
        })
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.spinner-border {
    width: 3rem;
    height: 3rem;
}
</style>