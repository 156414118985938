<template></template>
<script>
  export default {
    name: 'InstallPage',
    mounted () {
      let self = this
      if (self.isIOS()) {
        window.location.href = process.env.VUE_APP_APPLE_STORE
      } else {
        window.location.href = process.env.VUE_APP_GOOGLE_PLAY
      }
    },
    methods: {
      isIOS () {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      }
    }
  }
</script>
