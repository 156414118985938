<template>
  <div>
    <div
      v-if="isShowHodoService"
      class="redeem-announcement p-4 pb-6 mb-20 relative mx-auto h-80 md:h-48"
    >
      <div class="width-fill bg-blue-900 rounded-lg absolute z-20">
        <svg
          class="absolute cursor-pointer top-2 right-2"
          @click="closeRedeemAnnouncement()"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.0001 10.9392L5.6361 4.5752L4.57544 5.63586L10.9394 11.9998L4.57544 18.3638L5.6361 19.4244L12.0001 13.0605L18.364 19.4244L19.4247 18.3638L13.0607 11.9998L19.4247 5.63586L18.364 4.5752L12.0001 10.9392Z"
            fill="white"
          />
        </svg>

        <div class="p-6 radius-18">
          <div class="pb-2 flex items-center gap-1">
            <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 9.6565V4.5H22V9.6565C20.9408 10.034 20.1818 11.0526 20.1818 12.25C20.1818 13.4474 20.9408 14.466 22 14.8435V20H2V14.8435C3.05927 14.466 3.81818 13.4474 3.81818 12.25C3.81818 11.0526 3.05927 10.034 2 9.6565Z" stroke="black" stroke-width="2" stroke-linejoin="round"/>
              <path d="M9.5 8L12 10.5L14.5 8" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 11H15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9 14.083H15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 11V17" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>-->
            <span class="fs-20 fw-700 text-white mb-0">{{ $t("redeem.lbl_congratulation") }}</span>
          </div>
          <div
            class="text-white mb-2"
            v-html="$t('redeem.lbl_congratulation_to_you', {0:$user && $user.doctor ? $user.doctor.name : '', 1:voucher_info.DiscountType === 1 ? voucher_info.discount_money + 'VND' : voucher_info.DiscountPercent + '%'})"
          ></div>
          <div class="text-white mb-2">
            <p
              class="p-0 m-0"
              v-html="$t('redeem.lbl_voucher_due_date', {0:voucher_info.code, 1:voucher_info.start_date, 2:voucher_info.expired_date})"
            ></p>
          </div>
          <button
            class="fs-16 font-bold text-blue-900 btn cursor-pointer mb-2"
            @click="navigateToPurchaseScreen"
            style="text-underline-offset: 6px;"
          >{{ $t("redeem.lbl_activate_now") }}</button>
          <div class="mb-0 mt-2 italic" style="font-size: 12px; color:#a5aaaf">
            <p class="p-0 m-0">{{ $t("redeem.lbl_voucher_cannot_be_exchanged") }}</p>
            <p
              class="p-0 m-0"
              v-html="$t('redeem.lbl_contact', {0:'Hong Nhung', 1:'0977.339.188'} )"
            ></p>
          </div>
        </div>
      </div>
      <div
        class="d-none d-md-block width-fill height-fill bg-blue-600 rounded-lg absolute top-6 right-6 z-10"
      ></div>
      <div
        class="d-none d-md-block width-fill height-fill bg-blue-300 rounded-lg absolute top-4 right-4"
      ></div>
    </div>
    <component :is="dashboard"></component>
  </div>
</template>
<script>
import appUtils from '../utils/appUtils'

export default {
  name: 'HomePage',
  data () {
    return {
      open_time: '',
      user: {},
      clinic: {},
      isShowRedeemAnnouncement: true,
      voucher_info: {
        code: '',
        discount_money: 0,
        start_date: '',
        expired_date: ''
      }
    }
  },
  mounted () {
    this.handleGetVoucherInfo()

    if (appUtils.getLocalUser()) return
    if (this.$globalClinic) return
    this.open_time = new Date()
    // this.sendAmplitude('Open Home', {
    //   'Screen': 'Home',
    //   'User type': appUtils.isClinic() ? 'clinic' : appUtils.isDoctor() ? 'doctor' : appUtils.isAdmin() ? 'admin' : '',
    //   'Timestamp': window.moment(this.open_time).format('HH:mm DD-MM-YYYY'),
    //   'Root Screen': 'Home'
    // })
    let self = this
    self.user = appUtils.getLocalUser()
    self.clinic = this.$globalClinic
    self.sendAmplitude(
      self.$t('workspace_setting.lbl_open_workspace')[self.$orgModel],
      {
        'doctor id':
          self.user.doctor && self.user.doctor.id ? self.user.doctor.id : '',
        'doctor name': self.user && self.user.name ? self.user.name : '',
        'workspace id': self.clinic && self.clinic.id ? self.clinic.id : '',
        'workspace name':
          self.clinic && self.clinic.name ? self.clinic.name : '',
        timestamp: window.moment(new Date()).format('HH:mm DD-MM-YYYY')
      }
    )
  },
  computed: {
    dashboard () {
      if (appUtils.isClinic()) {
        return 'clinic-dashboard'
      }

      if (appUtils.isDoctor()) {
        return 'doctor-dashboard'
      }

      if (appUtils.isAdmin()) {
        return 'admin-dashboard'
      }

      return 'app-dashboard'
    },
    orgId () {
      const orgInfo = this.$user?.members?.[0]

      return (
        this.$globalClinic?.org_id || this.$globalOrg?.id || orgInfo?.org_id
      )
    },
    isShowHodoService () {
      const isOwner =
        this.$globalClinic?.organization?.owner_id === this.$user?.id ||
        this.$globalOrg?.owner_id === this.$user?.id ||
        this.$user?.members?.[0]?.role === 1
      return (
        isOwner &&
        this.voucher_info.Status === 1 &&
        this.$user.is_new_customer === 1 &&
        this.isShowRedeemAnnouncement
      )
    }
  },
  watch: {
    orgId () {
      this.handleGetVoucherInfo()
    }
  },
  methods: {
    closeRedeemAnnouncement () {
      this.isShowRedeemAnnouncement = false
    },
    navigateToPurchaseScreen () {
      this.$router.push({
        path: '/doctor/setting',
        query: { type: 'account' }
      })
    },
    async handleGetVoucherInfo () {
      if (!this.orgId || this.$user?.is_new_customer !== 1) return

      try {
        await this.$rf
          .getRequest('DoctorRequest')
          .getVoucherInfo({
            org_id: this.orgId
          })
          .then(res => {
            if (!res.data) return

            this.voucher_info = {
              ...res.data,
              code: res.data?.Code?.toUpperCase() || '',
              discount_money: appUtils.formatMoney(res.data?.DiscountMoney),
              start_date: appUtils.formatDate(res.data?.StartDate),
              expired_date: appUtils.formatDate(res.data?.ExpiredAt)
            }
          })
          .catch(e => {
            console.log(e)
          })
      } catch (error) {
        console.log(error)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // let self = this
    // self.sendAmplitude('Leave Home', {
    //   'Screen': 'Home',
    //   'User type': appUtils.isClinic() ? 'clinic' : appUtils.isDoctor() ? 'doctor' : appUtils.isAdmin() ? 'admin' : '',
    //   'Timestamp': window.moment(new Date()).format('HH:mm DD-MM-YYYY'),
    //   'Root Screen': 'Home',
    //   'Time Spent': Math.ceil((new Date() - this.open_time) / 1000) + ' Second'
    // })
    next()
  }
}
</script>

<style scoped lang="scss">
.redeem-announcement {
  // height: 150px;
  max-width: 1568px;
  .width-fill {
    width: calc(100% - 4rem);
    &.height-fill {
      height: 201px;
    }
  }
}
// .fade-out-image {
//   animation: fadeOut 5s;
// }
// @keyframes fadeOut {
//   0% {
//     opacity: 1;
//     height: 80px;
//   }
//   100% {
//     opacity: 0;
//     height: 0px;
//   }
// }
</style>
