<template>
  <div>
    <div class="member-wraper" v-loading="loading">
      <p class="text-xl font-semibold">Phân công vai trò nhân viên Livestream</p>
      <div v-if="allMembers && allMembers.length" class="mb-3">
        <template
          v-if="((selectedMembers && selectedMembers.length) || isOpenAssignSection) && !loading"
        >
          <p
            v-if="(!selectedMembers || !selectedMembers.length)"
            class="fs-16 fw-500"
          >{{ "Chưa phân công vai trò trong buổi phát trực tiếp" }}</p>
          <div v-else class="overflow-y-auto overflow-x-hidden" :style="{maxHeight: '400px'}">
            <div
              class="d-flex align-items-center mb-2 checkbox-wrapper-1 justify-between"
              href="javascript:;"
              v-for="(member, i) in selectedMembers"
              :key="i + 'selected-mem'"
            >
              <p class="d-flex align-items-center mb-0 w-30">
                <span
                  v-if="member && member.avatar"
                  class="img-avatar"
                  :style="{
                  'background-image':
                      'url(' + getUrlImage(member.avatar) + ')',
                  }"
                ></span>
                <span v-if="member && !member.avatar" class="img-avatar">
                  {{
                  member.name
                  ? member.name.charAt(0)
                  : member.email
                  ? member.email.charAt(0)
                  : ""
                  }}
                </span>
                <span class="list-item-name">
                  <span class="d-block mb-0 robo-14-400">
                    {{
                    member && member.name
                    }}
                  </span>
                  <span class="robo-12-400" v-if="member.id">
                    {{
                    member && member.chuc_vu
                    }}
                  </span>
                </span>
              </p>
              <el-select
                v-model="member.live_role"
                class="w-50"
                @change="(role_name) => {assignMember(member.id, role_name)}"
              >
                <el-option
                  v-for="role in roles"
                  :key="role.role_id"
                  :value="role.name"
                  :label="role.name_capitalized"
                ></el-option>
              </el-select>
              <button
                data-v-72b4a568
                type="button"
                class="btn btn-FEF3F2 mr-2"
                @click="unassignMember(member.id)"
              >Xóa</button>
            </div>
          </div>
          <div v-if="isActiveDropdownAddMember" class="input-group border radius-10">
            <el-select
              ref="select_member"
              class="w-100"
              v-model="selectedCircleMemberIds"
              :placeholder="$t('lbl_enter_doctors__healthcare_professionals_name')"
              allow-create
              multiple
              filterable
            >
              <el-option
                v-for="member in allMembers"
                :key="member.id"
                :label="member.name"
                :value="member.id"
              ></el-option>
            </el-select>
            <button
              type="button"
              class="close border-0"
              aria-label="Close"
              @click="toggleDropdownAddMember"
            >
              <span class="robo-18-500">&times;</span>
            </button>
          </div>
          <div
            v-else
            class="btn-plus w-100 mt-3 mb-3"
            @click="toggleDropdownAddMember"
            style="padding: 4px;"
          >
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 cursor-pointer w-100"
            >
              <path
                opacity="0.1"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.84 20.1595H27.72C28.1839 20.1595 28.56 20.5355 28.56 20.9995C28.56 21.4634 28.1839 21.8395 27.72 21.8395H21.84V27.7195C21.84 28.1834 21.4639 28.5595 21 28.5595C20.5361 28.5595 20.16 28.1834 20.16 27.7195V21.8395H14.28C13.8161 21.8395 13.44 21.4634 13.44 20.9995C13.44 20.5355 13.8161 20.1595 14.28 20.1595H20.16V14.2795C20.16 13.8155 20.5361 13.4395 21 13.4395C21.4639 13.4395 21.84 13.8155 21.84 14.2795V20.1595Z"
                fill="#20419B"
              />
            </svg>
          </div>
        </template>
        <template v-else>
          <p class="fs-16 fw-500">{{ "Chưa phân công vai trò trong buổi phát trực tiếp" }}</p>
          <div class="btn-plus w-100 mt-3 mb-3" @click="openAssignSection">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 cursor-pointer w-100"
            >
              <path
                opacity="0.1"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.84 20.1595H27.72C28.1839 20.1595 28.56 20.5355 28.56 20.9995C28.56 21.4634 28.1839 21.8395 27.72 21.8395H21.84V27.7195C21.84 28.1834 21.4639 28.5595 21 28.5595C20.5361 28.5595 20.16 28.1834 20.16 27.7195V21.8395H14.28C13.8161 21.8395 13.44 21.4634 13.44 20.9995C13.44 20.5355 13.8161 20.1595 14.28 20.1595H20.16V14.2795C20.16 13.8155 20.5361 13.4395 21 13.4395C21.4639 13.4395 21.84 13.8155 21.84 14.2795V20.1595Z"
                fill="#20419B"
              />
            </svg>
          </div>
        </template>
      </div>
      <div v-else>
        <p class="fs-16 fw-500">Không có nhân sự nào trong Tổ chức</p>
      </div>
      <button
        class="btn bg-pri bd-pri text-white save-btn"
        :disabled="loading || !selectedMembers || !selectedMembers.length"
        type="button"
        @click="goToStream"
      >{{ $t("circle_feature.lbl_go_to_conference") }}</button>
    </div>
  </div>
</template>
  
<script>
import appUtils from '../../utils/appUtils'
import { AppPagination } from '@/components/Common'

export default {
  name: 'LiveStreamOrgAssignRole',
  components: { AppPagination },
  data () {
    return {
      allMembers: [],
      selectedCircleMemberIds: [],
      loading: false,
      isOpenAssignSection: false,
      isActiveDropdownAddMember: false,
      user_role: ''
    }
  },
  props: ['live_room_id', 'roles'],
  computed: {
    selectedMembers () {
      const selectedMems =
        this.allMembers &&
        this.allMembers?.filter(mem =>
          this.selectedCircleMemberIds.includes(mem.id)
        )
      return selectedMems
    }
  },
  async created () {
    await this.getOrgMembers()
  },
  methods: {
    async assignMember (org_member_id, role_name) {
      this.loading = true
      const params = {
        org_member_id: org_member_id,
        role: role_name
      }
      await this.$rf
        .getRequest('DoctorRequest')
        .assignOrgLivestreamRole(params)
        .then(r => {
          this.allMembers.find(
            mem => mem.id === org_member_id
          ).one_hundred_ms_role = role_name

          this.loading = false
        })
    },
    unassignMember (org_member_id) {
      this.assignMember(org_member_id, 'viewer')
      this.selectedCircleMemberIds = this.selectedCircleMemberIds.filter(
        id => id !== org_member_id
      )
    },
    toggleDropdownAddMember () {
      this.isActiveDropdownAddMember = !this.isActiveDropdownAddMember
    },
    getUrlImage (path) {
      return appUtils.getImageURL(path)
    },
    openAssignSection () {
      this.isOpenAssignSection = true
      this.isActiveDropdownAddMember = true
      this.$nextTick(() => this.$refs.select_member?.focus())
    },
    async getOrgMembers () {
      if (!this.$user) return

      const response = await this.$rf
        .getRequest('DoctorRequest')
        .getOrgMembersV2({
          orgId: this.orgId,
          status: 1,
          limit: 10000,
          page: 1,
          q: '',
          sort_by: 'desc'
        })
      this.allMembers = response.data?.data
    },
    async goToStream () {
      await this.getCurrentUserRole().then(() => {
        let token = appUtils.getLocalToken()
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.live.hodo.app'
        } else {
          dm = 'https://live.hodo.app'
        }
        // TODO
        const routeRaw = `${dm}/redirect.html?token=${token}&room_id=${
          this.live_room_id
        }&org_id=${this.$globalOrg.id}&type=streaming&role=${this.user_role ||
          'viewer'}`
        window.open(routeRaw, '_blank')
      })
    },
    async getCurrentUserRole () {
      await this.$rf
        .getRequest('DoctorRequest')
        // TODO
        .checkMyRoleLivestreamRoom({
          org_id: this.$globalOrg.id,
          user_id: this.$user.id
        })
        .then(res => {
          this.user_role = res.data?.user_role || 'viewer'
        })
        .catch(e => {
          console.log(e)
          this.$toast.open({
            message: e,
            type: 'error'
          })
        })
    }
  }
}
</script>

<style scoped lang="scss">
.member-wraper {
  width: 80%;
  padding: 20px 30px 40px 30px;
  min-height: 250px;
  border-radius: 5px;
  border: 1px solid grey;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.save-btn {
  right: 10px;
  bottom: 10px;
  position: absolute;
}
.box-member {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 10px;
}

.img-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #20419b no-repeat center/cover;
}

.list-item-name {
  /* width: calc(100% - 60px); */
}

.list-item-name span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.dropdown-list-m {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.btn-plus {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px dashed #20419b;
  line-height: 20px;
  color: #20419b;
  padding: 8px;
}
.w-90 {
  width: 90% !important;
}
.w-30 {
  width: 30% !important;
}
.close {
  display: none;
}
.input-group:hover {
  position: relative;
  .close {
    display: inline-block;
    border-radius: 50% !important;
    background-color: #fef3f2;
    color: #b42318;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 24px;
  }
}
</style>

<style>
.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}
.checkbox-wrapper-1 [type="checkbox"].substituted {
  margin: 0;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox-wrapper-1 [type="checkbox"].substituted + label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1.15em;
  width: 1.15em;
  margin-right: 0.6em;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset,
    0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
      no-repeat center,
    white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}
.checkbox-wrapper-1 [type="checkbox"].substituted:enabled:active + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:enabled
  + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
  background-color: #f0f0f0;
}
.checkbox-wrapper-1 [type="checkbox"].substituted:checked + label:before {
  background-color: #3b99fc;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled
  + label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55),
    0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:focus:active + label:before,
.checkbox-wrapper-1 [type="checkbox"].substituted:focus + label:active:before {
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55),
    0 0 0 5px rgba(65, 159, 255, 0.3);
}
.checkbox-wrapper-1 [type="checkbox"].substituted:disabled + label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type="checkbox"].substituted.dark + label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled
  + label:active:before {
  background-color: #444;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset,
    0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
}
.checkbox-wrapper-1 [type="checkbox"].substituted.dark:checked + label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled
  + label:active:before {
  background-color: #c68035;
}
</style>
  